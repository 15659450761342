// 此文件为全局过滤器文件，可以在里面添加全局过滤器，使用直接在html中{{ xx | xx }}方式使用
import moment from "moment";
export default {
	/**
	 * @param { Number } date 时间戳（秒）
	 * @returns { String }
	 * @description: 将时间戳秒格式化为YYYY-MM-DD格式
	 */
	dateFormat: date => {
		if (date) {
			return moment(new Date(date * 1000)).format("YYYY-MM-DD");
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 班级类型
	 */
	classType: type => {
		if (type === 1) {
			return "长期";
		} else if (type === 2) {
			return "短期";
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 班级状态
	 */
	classStatus: status => {
		switch (status) {
			case 1:
				return "开班中";
			case 2:
				return "已结班";
			case 3:
				return "未开班";
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 学员性别
	 */
	studentSex: sex => {
		switch (sex) {
			case 0:
				return "女";
			case 1:
				return "男";
			case 2:
				return "未知";
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 家长身份
	 */
	elderType: type => {
		switch (type) {
			case 1:
				return "妈妈";
			case 2:
				return "爸爸";
			case 3:
				return "爷爷";
			case 4:
				return "奶奶";
			case 5:
				return "外公";
			case 6:
				return "外婆";
			case 7:
				return "本人";
			case 8:
				return "其他";
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 课程状态
	 */
	courseStatus: type => {
		switch (type) {
			case 1:
				return "未开始";
			case 2:
				return "进行中";
			case 3:
				return "已结束";
			case 5:
				return "已取消";
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 教练状态
	 */
	coachStatus: type => {
		switch (type) {
			case 1:
				return "待审核";
			case 2:
				return "已加入";
			case 3:
				return "已拒绝";
				// case 4:
				// 	return "已禁用";
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 教练性别
	 */
	coachSex: type => {
		switch (type) {
			case 0:
				return "女";
			case 1:
				return "男";
			case 2:
				return "未知";
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 班级学员状态
	 */
	ClassAuditStatus: type => {
		switch (type) {
			case 1:
				return "已加入";
			case 2:
				return "待审核";
			case 3:
				return "已拒绝";
		}
	},

	/**
	 * @param { Number }
	 * @returns { String }
	 * @description: 消息通知类型
	 */
	NoticeType: type => {
		switch (type) {
			case 1:
				return "新建课程通知";
			case 2:
				return "修改课程通知";
			case 3:
				return "取消课程通知";
			case 4:
				return "消息通知";
			case 5:
				return "请假通知";
			case 6:
				return "审核通知";
		}
	},
};