<template>
  <div class="layout-box">
    <!-- 侧边导航栏 -->
    <Sidebar></Sidebar>
    <!-- 右边内容区 -->
    <div :class="[navbar.menuIsOpen ? 'main-container-open' : 'main-container','main-box']">
      <!-- 用户信息展示区 -->
      <Navbar></Navbar>
      <!-- 内容区域 -->
      <AppMain></AppMain>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/sidebar/sidebar.vue";
import AppMain from "./components/appMain/appMain.vue";
import Navbar from "./components/navbar/navbar.vue";
import { mapState } from "vuex";
export default {
  components: {
    Sidebar,
    AppMain,
    Navbar
  },
  computed: {
    ...mapState(['navbar'])
  }
}
</script>

<style lang='less' scoped>
.layout-box {
  position: relative;
  height: 100%;
  width: 100%;
}
.main-box {
  transition: margin-left 0.28s;
}
.main-container {
  position: relative;
  margin-left: 208px;
  display: flex;
}
.main-container-open {
  position: relative;
  margin-left: 65px;
  display: flex;
}
</style>