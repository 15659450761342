import http from "@/utils/http";

/**
 * 微信扫码登录
 * @param {object} data 微信扫码登录
 */
export const elderLogin = data => {
	return http.post("/wechat/webElderLogin", data);
};

/**
 * 获取验证码
 * @param {object} data
 */
export const sendClubTeacherPhoneBound = data => {
	return http.post("/club/sendClubTeacherPhoneBound", data);
};

/**
 * 图形验证码
 * @param {object} data
 */
export const getCaptcha = data => {
	return http.post("/captCha", data);
};

/**
 * 根据扫码信息和验证码登录
 * @param {object} data
 */
export const clubTeacherBound = data => {
	return http.post("/club/clubTeacherBound", data);
};

/**
 * 教练验证码,密码登录
 * @param {object} data
 */
export const teacherLogin = data => {
	return http.post("/club/teacherLogin", data);
};

/**
 * 获取用户信息
 * @param {object} data
 */
export const getTeacherLoginInfo = data => {
	return http.get("/club/getTeacherLoginInfo", data);
};

/**
 * 获取token
 * @param {object} data 获取token
 */
export const getToken = data => {
	return http.post("/getToken", data);
};

/**
 * 获取Cos参数
 * @param {object} data 需要传的数据
 */
export const getCosConfig = data => {
	return http.post("/getCosConfig", data);
};

/**
 * 获取教练所在俱乐部列表
 */
export const getOrgByTeacher = data => {
	return http.get("/club/getOrgByTeacher", data);
};

/**
 * 切换俱乐部
 */
export const teacherChangeOrg = data => {
	return http.post("/club/teacherChangeOrg", data);
};

/**
 * 发送验证码
 */
export const sendPhoneCode = data => {
	return http.post("/sendPhoneCode", data);
};

/**
 * 验证验证码
 */
export const checkPhoneCode = data => {
	return http.post("/checkPhoneCode", data);
};