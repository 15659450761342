<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
      <div v-if="collapse" class="sidebar-logo-link">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <!-- <h1 v-else class="sidebar-title">{{ userInfo.OrgName }} </h1> -->
        <h1 v-else class="sidebar-title">青训小将综合管理平台</h1>
      </div>
      <div v-else class="sidebar-logo-link">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <!-- <h1 class="sidebar-title" @click="goHome">{{ userInfo.OrgName }} </h1> -->
        <h1 class="sidebar-title" @click="goHome">青训小将综合管理平台 </h1>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'SidebarLogo',
  computed: {
    ...mapState(['navbar', 'userInfo'])
  },
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: require('@/assets/sidebar-logo.png')
    }
  },
  methods: {
    goHome() {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #dfdfdf;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .sidebar-logo {
      width: 30px;
      height: 30px;
      margin: 0 8px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      font-weight: bold;
      // color: #21293a;
      color: #6a757e;
      font-size: 16px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      line-height: 18px;
      cursor: pointer;
      width: calc(100% - 40px);
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
