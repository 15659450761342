<template>
  <div :class="[navbar.menuIsOpen ? 'close-header' : '', 'header']">
    <div class="navbar">
      <div class="navbar-left">
        <img src="@/assets/fold.png" @click="changeMenuState" class="icon" v-if="navbar.menuIsOpen" />
        <img src="@/assets/unfold.png" @click="changeMenuState" class="icon" v-else />
        <div>
          <breadcrumb />
        </div>
      </div>
      <div class="navbar-right">
        <!-- <img :src="userInfo.Logo" alt="" class="last-img logo-box"> -->
        <el-image v-if="userInfo.Logo" :src="`${userInfo.Logo}`" :preview-src-list="[`${userInfo.Logo}`]" class="last-img logo-box">
        </el-image>
        <el-image v-else :src="`${defaultImg}`" :preview-src-list="[`${defaultImg}`]" class="last-img logo-box">
        </el-image>
        <!-- <img v-else src="@/assets/defaultLogo.png" class="last-img logo-box"> -->
        <el-dropdown trigger="click" placement=bottom>
          <span class="el-dropdown-link">
            <span class="nav-right">
              <span>{{userInfo.OrgName}} - <span class="noBlod">{{ userInfo.Name }}</span></span>
              <!-- <span>{{ userInfo.Name }}-{{userInfo.Phone}}</span> -->
            </span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item class="new-dropdown new-item">
              <span>{{ userInfo.Name }}-{{userInfo.Phone}}</span>
            </el-dropdown-item>
            <el-dropdown-item divided class="new-dropdown" @click.native="handleManage" v-if="userInfo.Admin===true">账号设置</el-dropdown-item>
            <el-dropdown-item divided @click.native="OrgNameLists" class="new-dropdown" v-if="userInfo.HasClub===true">切换俱乐部</el-dropdown-item>
            <el-dropdown-item divided @click.native="handleLogOut" class="new-dropdown">退出登陆</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog title="切换俱乐部" :visible.sync="OrgNameVisible" width="30%" append-to-body :close-on-click-modal='false'>
      <el-select class=" statusBox" v-model="userInfo.OrgId" placeholder="请选择">
        <el-option v-for="item in OrgNameList" :key="item.Org.Id" :label="item.Org.Name" :value="item.Org.Id">
        </el-option>
      </el-select>
      <div class="saveRevise">
        <el-button type="primary" @click="updateClub()">进入</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import breadcrumb from '@/layout/components/breadcrumb/breadcrumb.vue';
import { mapState, mapMutations } from "vuex";
import { ACCESS_TOKEN, USER_INFO } from "@/config/data.js";
import { removeSessionStorage } from "@/utils/cache";
import { getOrgByTeacher, teacherChangeOrg } from "@/service/auth";

export default {
  name: 'Navbar',
  computed: {
    ...mapState(['navbar', 'userInfo'])
  },
  components: {
    breadcrumb,

  },
  data() {
    return {
      defaultImg: require('@/assets/defaultLogo.png'),
      classDate: [],
      OrgNameList: [], // 俱乐部列表
      OrgNameVisible: false, // 俱乐列表弹窗
    }
  },
  created() {
    this.getOrgByTeacher();
  },
  mounted() {
    if (!this.userInfo.Id) {
      this.$message.error('无登录信息');
      removeSessionStorage(USER_INFO);
      removeSessionStorage(ACCESS_TOKEN);
      this.$router.push('/');
    } else {
      // this.classDate = Array.isArray(this.userInfo.AtClass) && this.userInfo.AtClass.map(item => { return item.Manager === true })
    }
  },
  methods: {
    ...mapMutations('navbar', {
      changeMenuState: 'changeMenuState'
    }),
    async getOrgByTeacher() {
      let res = await getOrgByTeacher();
      this.OrgNameList = res;
    },

    /**
     * 退出登录
     */
    handleLogOut() {
      this.$confirm('确定退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: 'true'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '退出成功!',
          center: 'true'
        });
        removeSessionStorage(USER_INFO);
        removeSessionStorage(ACCESS_TOKEN);
        this.$router.push('/');
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出',
          center: 'true'
        });
      });
    },

    handleManage() {
      this.$router.push({
        path: '/organizational/accountSettings',
      })
    },

    // 切换俱乐部
    OrgNameLists() {
      this.OrgNameVisible = true;
    },
    async updateClub() {
      let res = await this.$confirm('确定进入该俱乐部吗？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确 定',
        cancelButtonText: '取 消',
        type: 'warning',
        center: true,
      });
      if (res !== 'confirm') return;
      let ret = await teacherChangeOrg({ OrgId: this.userInfo.OrgId });
      this.$store.commit('set_userInfo', ret);
      this.$message({
        message: '进入成功',
        type: 'success',
        center: true,
      });
      this.OrgNameVisible = false;
      this.$router.go(0);
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: calc(100% - 208px);
  transition: width 0.28s;
  height: 50px;
  position: fixed;
  background: #fff;
  z-index: 999;
}

.close-header {
  width: calc(100% - 65px);
}

.navbar {
  height: 100%;
  min-width: 440px;
  padding: 0 10px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .navbar-left {
    display: flex;
    align-items: center;
    div {
      margin-left: 12px;
    }
    .icon {
      width: 16px;
      height: 16px;
      margin-left: 14px;
      cursor: pointer;
    }
  }
  .navbar-right {
    font-size: 14px;
    display: flex;
    align-items: center;
    img,
    .logo-box {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 26px;
    }
    .last-img {
      margin-right: 8px !important;
    }
    i {
      font-size: 16px;
      margin: 0 24px 0 8px;
      cursor: pointer;
    }
  }
}
.nav-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // color: #6a757e;
  font-size: 16px;
  font-weight: bold;
  color: #21293a;
  .noBlod {
    font-weight: 400;
    color: #6a757e;
  }
}
.new-dropdown {
  text-align: center;
}
.new-dropdown:hover {
  color: #8fcc80 !important;
}
.new-item {
  background: rgba(#6a757e, 0.2);
}
.new-item:hover {
  cursor: text;
  color: #6a757e !important;
  background: rgba(#6a757e, 0.2) !important;
}
.el-dropdown-link {
  cursor: pointer;
  color: #8fcc80;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.saveRevise {
  display: flex;
  justify-content: center;
}
.statusBox {
  width: 100%;
  margin-bottom: 24px;
}
/deep/.el-input .el-input__inner {
  height: 36px;
}
</style>