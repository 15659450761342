// 此文件为全局存储工具文件
import Cookies from "js-cookie";
import storage from "good-storage";

/**
 * @description 设置cookie
 * @param { String } key
 * @param { * } value
 * @param { * } options
 * @returns {*}
 */
export const saveCookie = (key, value, options) => {
	Cookies.set(key, value, options);
	return value;
};

/**
 * @description 获取cookie
 * @param { String } key
 * @param { * } defaultValue
 * @returns {*}
 */
export const loadCookie = (key, defaultValue) => {
	return Cookies.get(key) || defaultValue;
};

/**
 * @description 删除cookie
 * @param { String } key
 * @returns { String }
 */
export const removeCookie = key => {
	Cookies.remove(key);
	return "";
};

/**
 * @description 设置本地存储
 * @param { String } key
 * @param { * } value
 * @returns {*}
 */
export const saveStorage = (key, value) => {
	storage.set(key, value);
	return value;
};

/**
 * @description 获取本地存储
 * @param { String } key
 * @param { * } defaultValue
 * @returns {*}
 */
export const loadStorage = (key, defaultValue) => {
	return storage.get(key, defaultValue);
};

/**
 * @description 删除本地存储
 * @param { String } key
 * @returns { String }
 */
export const removeStorage = key => {
	storage.remove(key);
	return "";
};

/**
 * @description 保存会话存储
 * @param { String } key
 * @param { * } value
 * @returns {*}
 */
export const saveSessionStorage = (key, value) => {
	storage.session.set(key, value);
	return value;
};

/**
 * @description 获取会话存储
 * @param { String } key
 * @param { * } defaultValue
 * @returns {*}
 */
export const loadSessionStorage = (key, defaultValue) => {
	return storage.session.get(key, defaultValue);
};

/**
 * @description 删除会话存储
 * @param { String } key
 * @returns { String }
 */
export const removeSessionStorage = key => {
	storage.session.remove(key);
	return "";
};
