<template>
  <fragment v-if="!item.hidden">
    <!-- 只有一个子路由或没有子路由 -->
    <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren)">
      <template v-if="onlyOneChild.meta">
        <el-menu-item @click="goToPath(resolvePath(onlyOneChild.path))" :index="resolvePath(onlyOneChild.path)" v-if="onlyOneChild.meta.title==='首页'" class="first-item">
          <template v-if="onlyOneChild.meta || item.meta">
            <img v-if="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :src="`${onlyOneChild.meta.icon || (item.meta && item.meta.icon)}`" class="sidebar-img" />
            <span slot="title">{{ onlyOneChild.meta.title }}</span>
          </template>
        </el-menu-item>
        <el-menu-item @click="goToPath(resolvePath(onlyOneChild.path))" :index="resolvePath(onlyOneChild.path)" v-else>
          <template v-if="onlyOneChild.meta || item.meta">
            <img v-if="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :src="`${onlyOneChild.meta.icon || (item.meta && item.meta.icon)}`" class="sidebar-img" />
            <span slot="title">{{ onlyOneChild.meta.title }}</span>
          </template>
        </el-menu-item>
      </template>
    </template>
    <!-- 多个子路由 -->
    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title" v-if="item.meta">
        <img v-if="item.meta && item.meta.icon" :src="`${item.meta.icon}`" class="sidebar-img" />
        <span slot="title">{{ item.meta.title }}</span>
      </template>
      <sidebar-item v-for="child in item.children" :key="child.path" :item="child" :base-path="resolvePath(child.path)" class="nest-menu" />
    </el-submenu>
  </fragment>
</template>

<script>
import path from "path";
import { mapState } from "vuex";
export default {
  name: 'SidebarItem',
  props: {
    item: { // 传过来的整个route
      type: Object,
      required: true
    },
    basePath: { // 基础路径
      type: String,
      default: ''
    }
  },

  data() {
    return {
      onlyOneChild: null // 当只有一个孩子路由时临时存储
    }
  },

  computed: {
    ...mapState(['navbar']),
  },

  methods: {
    /**
     * 判断有几个孩子路由需要展示
     */
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false;
        } else {
          // 临时存储（如果只有一个显示子项，则将使用）
          this.onlyOneChild = item;
          return true;
        }
      });

      // 当只有一个子路由器时，默认情况下显示子路由器
      if (showingChildren.length === 1) {
        return true;
      }

      // 如果没有要显示的子路由器，则显示父路由器
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true };
        return true;
      }

      return false;
    },
    /**
     * 合并路径
     */
    resolvePath(routePath) {
      // 将路径组合
      return path.resolve(this.basePath, routePath);
    },
    /**
     * 跳转路径
     */
    goToPath(path) {
      if (path === this.$route.path) return;
      this.$router.push({ path });
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-tooltip {
  padding-left: 16px !important;
}
</style>