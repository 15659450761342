<template>
  <div class="sidebar-container" :class="[navbar.menuIsOpen ? 'sidebar-box-Collapse' : 'sidebar-box']">
    <logo :collapse="navbar.menuIsOpen" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <!-- 菜单 -->
      <el-menu class="el-menu-vertical-demo" :default-openeds="openeds" :default-active="activeMenu" mode="vertical" :collapse="navbar.menuIsOpen">
        <sidebar-item v-for="route in routes" :key="route.path" :item="route" :base-path="route.path"></sidebar-item>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SidebarItem from './sidebarItem.vue';
import logo from '../logo/logo.vue';
import { mapState } from 'vuex';
export default {
  components: {
    SidebarItem,
    logo
  },
  data() {
    return {
      openeds: []
    }
  },
  created() {
    this.getOpeneds();
  },
  computed: {
    ...mapState(['navbar']),
    /**
     * 获取所有路由
     */
    routes() {
      return this.$router.options.routes;
    },
    /**
     * 当前选中菜单
     */
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      // 如果有指向选中菜单
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
  },
  watch: {
    navbar: {
      handler(newValue) {
        if (!newValue.menuIsOpen) {
          this.getOpeneds();
        }
      },
      deep: true
    }
  },
  methods: {
    getOpeneds() {
      let openeds = [];
      this.$router.options.routes.forEach(item => {
        if (item.children) {
          openeds.push(item.path)
        }
      });
      this.openeds = openeds;
    }
  }
}
</script>

<style lang='scss' scoped>
.el-menu {
  height: 100%;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 208px;
  height: 100%;
}
.sidebar-container {
  width: 208px !important;
  transition: width 0.28s;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
}
.sidebar-box {
  width: 208px !important;
}
// 菜单折叠
.sidebar-box-Collapse {
  width: 65px !important;
}
</style>