import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Fragment from "vue-fragment"; // 类似template标签不占dom树的节点，常用于vue根节点
import "@/style/index.scss"; // 引入全局css文件
import "element-ui/lib/theme-chalk/index.css"; // 引入element-ui的css文件
import filter from "@/utils/filter.js"; // 引入过滤器文件
import "@/utils/element.js"; // 引入按需引入element组件文件
import directives from "@/utils/directive.js"; // 引入自定义指令文件

import dayjs from 'dayjs';
Vue.prototype.dayjs = dayjs;

Vue.config.productionTip = false;
Vue.use(Fragment.Plugin);

// 全局注册filter过滤器
for (const a in filter) {
	Vue.filter(a, filter[a]);
}

// 全局注册directive指令
for (const key in directives) {
	Vue.directive(key, directives[key]);
}
router.beforeEach((to, from, next) => {
	// window.document.title = to.meta.title === undefined ? '青训小将' : to.meta.title;
	window.document.title = '青训小将';
	if (to.meta.requireAuth) {
		let token = Cookies.get('access_token');
		let anonymous = Cookies.get('user_name');
		if (token) {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath
				}
			})
		}
	}
	next();
})

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");