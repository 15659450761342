import {
	saveSessionStorage,
	loadSessionStorage
} from "@/utils/cache.js";
export default {
	namespaced: true,
	state: {
		menuIsOpen: loadSessionStorage("sliderState") || false, // 菜单展开false为展开，true为收起(默认展开)
	},
	getters: {},
	mutations: {
		/**
		 * 切换菜单展开收起
		 */
		changeMenuState(state) {
			state.menuIsOpen = !state.menuIsOpen;
			saveSessionStorage("sliderState", state.menuIsOpen);
		},
	},
	actions: {},
};