// 此文件为element-ui按需引入文件
import Vue from "vue";
import {
	Button,
	Input,
	Form,
	FormItem,
	ButtonGroup,
	Menu,
	Submenu,
	MenuItem,
	MenuItemGroup,
	Icon,
	Loading,
	MessageBox,
	Message,
	Notification,
	Scrollbar,
	Pagination,
	Tree,
	Table,
	TableColumn,
	Popover,
	Dialog,
	Row,
	Col,
	Image,
	Upload,
	Select,
	Option,
	Radio,
	RadioGroup,
	RadioButton,
	Link,
	DatePicker,
	Tabs,
	TabPane,
	Breadcrumb,
	BreadcrumbItem,
	CheckboxGroup,
	CheckboxButton,
	Checkbox,
	TimeSelect,
	tooltip,
	Switch,
	Dropdown,
	DropdownMenu,
	DropdownItem
} from "element-ui";

Vue.use(TimeSelect);
Vue.use(CheckboxGroup);
Vue.use(CheckboxButton);
Vue.use(Checkbox);
Vue.use(BreadcrumbItem);
Vue.use(Breadcrumb);
Vue.use(Button);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(Scrollbar);
Vue.use(MenuItemGroup);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Icon);
Vue.use(ButtonGroup);
Vue.use(Pagination);
Vue.use(Tree);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Popover);
Vue.use(Dialog);
Vue.use(Row);
Vue.use(Col);
Vue.use(Image);
Vue.use(Upload);
Vue.use(Select);
Vue.use(Option);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Link);
Vue.use(DatePicker);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(tooltip);
Vue.use(Switch);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;

Vue.prototype.$msgbox = MessageBox;

Vue.prototype.$alert = MessageBox.alert;

Vue.prototype.$confirm = MessageBox.confirm;

Vue.prototype.$prompt = MessageBox.prompt;

Vue.prototype.$notify = Notification;

Vue.prototype.$message = Message;