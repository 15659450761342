// 此文件为网络请求底层封装
import axios from "axios";
import router from "@/router/index.js";
import Vue from "vue";
import {
	BASE_URL,
	ACCESS_TOKEN,
	USER_INFO
} from "@/config/data.js";
import {
	loadSessionStorage,
	removeSessionStorage
} from "@/utils/cache";

class Http {
	constructor(option) {
		this.instance = axios.create({
			...option,
			timeout: 20000,
		});

		// 添加请求拦截器
		this.instance.interceptors.request.use(
			config => {
				// 在发送请求之前做些什么
				const token = loadSessionStorage(ACCESS_TOKEN); // token存入会话存储
				if (token) {
					config.headers.Token = token; // 请求头带token，根据不同情况修改
				}
				return config;
			},

			error => {
				// 对请求错误做些什么
				return Promise.reject(error);
			}
		);

		// 添加响应拦截器
		this.instance.interceptors.response.use(
			response => {
				// 对响应数据做点什么
				const res = response.data;
				// 根据后台返回的code做判断，每个项目可能不同
				if (res.Code === "200") {
					return res.Data;
				}
				if (res.Code === "1107") {
					// 教练未注册
					return res;
				}
				if (res.Code === "1101") {
					// 登录信息不存在
					removeSessionStorage(ACCESS_TOKEN);
					removeSessionStorage(USER_INFO);
					router.push("/");
					return res;
				}
				Vue.prototype.$message.error(res.Msg);
				return Promise.reject(res);
			},
			error => {
				// 对响应错误做点什么
				this.handleError(error.response);
				return;
			}
		);
	}

	/**
	 * @description: 处理http状态码>=400的状态码
	 * @param { * } resInfo
	 */
	handleError(resInfo) {
		if (resInfo.status === 401) {
			// 删除token和用户信息
			removeSessionStorage(ACCESS_TOKEN);
			removeSessionStorage(USER_INFO);
			Vue.prototype.$message.error(resInfo.data && resInfo.data.ErrorMessage);
			router.push("/");
		} else {
			Vue.prototype.$message.error(resInfo.data && resInfo.data.ErrorMessage);
		}
	}

	get(url, params = {}, option = {}) {
		return this.instance.get(url, {
			params,
			...option,
		});
	}

	post(url, data, option) {
		return this.instance.post(url, data, option);
	}

	delete(url, params = {}, option = {}) {
		return this.instance.delete(url, {
			params,
			...option,
		});
	}

	put(url, data, option) {
		return this.instance.put(url, data, option);
	}
}

export default new Http({
	baseURL: BASE_URL,
	headers: {},
});