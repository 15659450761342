import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout/index.vue";

Vue.use(VueRouter);

/**
 * {
 *	path: "/home",
 *	name: "Home",
 *	component: Layout,
 *	meta: {
 *		title: "一级路由", // 路由标题
 *		icon: "menu", // 路由图标
 *	},
 *  hidden: true, // 是否展示，true不展示，默认为false展示
 *	children: [
 *		{
 *			path: "list",
 *			name: "List",
 *			component: () => import("@/views/home/list.vue"),
 *			meta: {
 *            title: "二级路由",
 *            keepAlive: true  // 是否缓存
 *      },
 *		},
 *		{
 *			path: "list1",
 *			name: "List1",
 *			component: () => import("@/views/home/list.vue"),
 *			meta: {
 *            title: "二级路由",
 *            activeMenu: "/home/list" // 不展示时对应常亮的菜单，只有当hidden为true时生效
 *      },
 *			hidden: true,
 *		},
 *	],
 *},
 *
 */

const routes = [{
		path: "/",
		name: "Login",
		component: () => import("@/views/login/index.vue"),
		hidden: true,
	},

	{
		path: "/bindPhone",
		name: "BindPhone",
		component: () => import("@/views/login/bindPhone.vue"),
		hidden: true,
	},

	{
		path: "/token/:token",
		name: "Token",
		component: () => import("@/views/login/token.vue"),
		hidden: true,
	},

	{
		path: "/home",
		name: "Home",
		redirect: "/home/basics",
		component: Layout,
		meta: {
			// title: "首页",
			icon: require("@/assets/home.png"),
		},
		children: [{
			path: "basics",
			name: "Basics",
			component: () => import("@/views/home/home.vue"),
			meta: {
				title: "首页",
			},
		}, ],
	},
	// 机构管理
	{
		path: "/organizational",
		name: "Organizational",
		component: Layout,
		meta: {
			title: "机构管理",
			icon: require("@/assets/organizational.png"),
		},
		children: [{
				path: "class",
				name: "Class",
				component: () => import("@/views/organizational/class.vue"),
				meta: {
					title: "班级管理",
					keepAlive: true, // 是否缓存
				},
			},
			{
				path: "accountSettings",
				name: "AccountSettings",
				component: () => import("@/views/organizational/accountSettings.vue"),
				meta: {
					title: "账号设置",
					activeMenu: "/organizational"
				},
				hidden: true,
			},
			{
				path: "classDetail",
				name: "ClassDetail",
				component: () => import("@/views/organizational/classDetail.vue"),
				meta: {
					title: "班级详情",
					activeMenu: "/organizational/class",
				},
				hidden: true,
			},
			{
				path: "coach",
				name: "Coach",
				component: () => import("@/views/organizational/coach.vue"),
				meta: {
					title: "教练管理",
				},
			},
			{
				path: "field",
				name: "Field",
				component: () => import("@/views/organizational/field.vue"),
				meta: {
					title: "场地管理",
				},
			},
			{
				path: "clothing",
				name: "Clothing",
				component: () => import("@/views/organizational/clothing.vue"),
				meta: {
					title: "服装管理",
				},
			},
			{
				path: "course",
				name: "Course",
				component: () => import("@/views/organizational/course.vue"),
				meta: {
					title: "展示课程",
					keepAlive: true, // 是否缓存
				},
			},
		],
	},
	// 教务管理
	{
		path: "/educational",
		name: "Educational",
		component: Layout,
		meta: {
			title: "教务管理",
			icon: require("@/assets/educational.png"),
		},
		children: [{
				path: "student",
				name: "Student",
				component: () => import("@/views/educational/student.vue"),
				meta: {
					title: "学员管理",
				},
			},
			{
				path: "curriculum",
				name: "Curriculum",
				component: () => import("@/views/educational/timeTable/curriculum.vue"),
				meta: {
					title: "课程列表",
					keepAlive: true, // 是否缓存
				},
			},
			{
				path: "readDetail",
				name: "ReadDetail",
				component: () => import("@/views/educational/timeTable/readDetail.vue"),
				meta: {
					title: "查看详情",
					activeMenu: "/educational/curriculum",
				},
				hidden: true,
			},
			{
				path: "createCurriculum",
				name: "CreateCurriculum",
				component: () =>
					import("@/views/educational/timeTable/createCurriculum.vue"),
				meta: {
					title: "新建课程",
					activeMenu: "/educational/curriculum",
				},
				hidden: true,
			},
			{
				path: "classSchedule",
				name: "ClassSchedule",
				component: () =>
					import("@/views/educational/timeTable/classSchedule.vue"),
				meta: {
					title: "班级课表",
					keepAlive: true, // 是否缓存
				},
			},
			// 考勤管理
			{
				path: "attendance",
				name: "Attendance",
				component: () => import("@/views/educational/attendance.vue"),
				meta: {
					title: "考勤管理",
				},
			},
			{
				path: "attendanceDetail",
				name: "AttendanceDetail",
				component: () => import("@/views/educational/attendanceDetail.vue"),
				meta: {
					title: "查看考勤",
					activeMenu: "/educational/attendance",
				},
				hidden: true,
			},
			// 消息通知
			{
				path: "notifies",
				name: "Notifies",
				component: () => import("@/views/educational/notifies.vue"),
				meta: {
					title: "消息通知",
				},
			},
			{
				path: "notifiesDetail",
				name: "NotifiesDetail",
				component: () => import("@/views/educational/notifiesDetail.vue"),
				meta: {
					title: "通知详情",
					activeMenu: "/educational/notifies",
				},
				hidden: true,
			},
		],
	},
	// 课程教研
	{
		path: "/teaching",
		name: "Teaching",
		component: Layout,
		meta: {
			title: "课程教研",
			icon: require("@/assets/teaching.png"),
		},
		children: [{
				path: "syllabus",
				name: "Syllabus",
				component: () => import("@/views/teaching/syllabus.vue"),
				meta: {
					title: "课程大纲",
				},
			},
			{
				path: "training",
				name: "Training",
				component: () => import("@/views/teaching/training.vue"),
				meta: {
					title: "课程模块",
				},
			},
		],
	},
];

const router = new VueRouter({
	routes,
});

router.beforeEach((to, from, next) => {
	// 消息通知传过来的路径处理，传过来的路径为以下格式将#/?后面的参数取出拼在后面
	if (window.location.search) {
		let search = window.location.search;
		let hostname = window.location.origin + window.location.pathname;
		window.location.replace(hostname + "#" + to.path + search);
		return;
	}
	next();
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

export default router;