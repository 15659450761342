// 此文件为全局常量文件
export const BASE_URL = process.env.VUE_APP_BASE_URL; // 基础请求路径 String

export const ACCESS_TOKEN = "Token"; // accessToken String

export const USER_INFO = "User_Info"; // 用户信息 {} Object

export const ClassTypeList = [{
		value: 1,
		label: "长期",
	},
	{
		value: 2,
		label: "短期",
	},
]; // 班级类型筛选数组

export const TeacherTypeList = [{
		value: 1,
		label: "主教练",
	},
	{
		value: 2,
		label: "助教",
	},
]; // 教练类型

export const StatusList = [{
		value: 1,
		label: "开班中",
	},
	{
		value: 2,
		label: "已结班",
	},
	{
		value: 3,
		label: "未开班",
	},
]; // 班级状态

export const ManagerList = [{
		value: false,
		label: "否",
	},
	{
		value: true,
		label: "是",
	},
]; // 是否管理员

export const parentTypeList = [{
		value: 1,
		label: "妈妈",
	},
	{
		value: 2,
		label: "爸爸",
	},
	{
		value: 3,
		label: "爷爷",
	},
	{
		value: 4,
		label: "奶奶",
	},
	{
		value: 5,
		label: "外公",
	},
	{
		value: 6,
		label: "外婆",
	},
	{
		value: 7,
		label: "本人",
	},
	{
		value: 8,
		label: "其他",
	}
]; // 身份列表

export const CourseStatusList = [{
		value: 1,
		label: "未开始",
	},
	{
		value: 2,
		label: "进行中",
	},
	{
		value: 3,
		label: "已结束",
	},
	{
		value: 5,
		label: "已取消",
	},
]; // 课程状态列表

export const CoachStatusList = [{
		value: 1,
		label: "待审核",
	},
	{
		value: 2,
		label: "已加入",
	},
	{
		value: 3,
		label: "已拒绝",
	},
	// {
	// 	value: 4,
	// 	label: "已禁用",
	// },
]; // 教练状态列表

export const ClassAuditStatusList = [{
		value: 1,
		label: "已加入",
	},
	{
		value: 2,
		label: "待审核",
	},
	{
		value: 3,
		label: "已拒绝",
	},
]; //班级审核状态