<template>
  <div :class="[navbar.menuIsOpen ? 'close-main' : '', 'app-main']">
    <transition name="fade-transform" mode="out-in">
      <!-- 动态添加需要缓存的组件 -->
      <keep-alive :include="cachedViews">
        <router-view class="main" :key="key" />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'AppMain',
  data() {
    return {
      cachedViews: []
    }
  },
  computed: {
    ...mapState(['navbar']),
    key() {
      return this.$route.path;
    }
  },
  methods: {
    /**
     * 递归循环添加需要缓存的组件
     */
    keepAliveStr(arr) {
      arr.forEach(item => {
        if (item.meta && item.meta.keepAlive) {
          this.cachedViews.push(item.name);
        }
        if (item.children && Array.isArray(item.children) && item.children.length) {
          this.keepAliveStr(item.children);
        }
      });
    }
  },
  created() {
    this.keepAliveStr(this.$router.options.routes);
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /*50 = navbar  */
  height: calc(100vh - 50px);
  transition: width 0.28s;
  margin-top: 50px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: rgba(240, 242, 245, 1);
  .main {
    height: 100%;
    box-sizing: border-box;
    // padding: 20px;
  }
}
</style>

