// 此文件为全局自定义指令文件
/**
 * @description: 自定义指令防止按钮一秒内快速点击两次按钮
 */
const preventReClick = {
	inserted(el, binding) {
		el.addEventListener("click", () => {
			if (!el.disabled) {
				el.disabled = true;
				setTimeout(() => {
					el.disabled = false;
				}, binding.value || 1000);
			}
		});
	},
};

/**
 * @description 输入框聚焦
 */
const focus = {
	inserted: el => {
		el.focus();
	},
};

/**
 * @description 防抖指令
 */
const debounce = {
	inserted: (el, binding) => {
		let timer;
		el.addEventListener("click", () => {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(() => {
				binding.value();
			}, 500);
		});
	},
};

/**
 * @description 按钮权限指令
 */
const permission = {
	inserted: (el, binding, vnode) => {
		const index = vnode.context.$route.meta.roles.findIndex(
			item => item === binding.value
		);
		index === -1 ? el.remove() : "";
	},
};

/**
 * @description 元素拖拽功能
 */
const draggable = {
	inserted: function (el) {
		el.style.cursor = "move";
		el.onmousedown = function (e) {
			let disx = e.pageX - el.offsetLeft;
			let disy = e.pageY - el.offsetTop;
			document.onmousemove = function (e) {
				let x = e.pageX - disx;
				let y = e.pageY - disy;
				let maxX =
					document.body.clientWidth -
					parseInt(window.getComputedStyle(el).width);
				let maxY =
					document.body.clientHeight -
					parseInt(window.getComputedStyle(el).height);
				if (x < 0) {
					x = 0;
				} else if (x > maxX) {
					x = maxX;
				}

				if (y < 0) {
					y = 0;
				} else if (y > maxY) {
					y = maxY;
				}

				el.style.left = x + "px";
				el.style.top = y + "px";
			};
			document.onmouseup = function () {
				document.onmousemove = document.onmouseup = null;
			};
		};
	},
};

export default {
	preventReClick,
	focus,
	debounce,
	permission,
	draggable,
};
